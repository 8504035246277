import IconButton from "@components/library/IconButton";
import { ArrowLeftIcon, XIcon } from "@heroicons/react/solid";
import Button from "@library/Button";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { FormikTextInput } from "../../molecules/FormikInput";
import React from "react";
import { handleSSOLogin } from "@/services/auth.service";
import { FormStatus } from "@components/llama/Signup/LoginForm";

interface Props {
  onBackClick: () => void;
  initialValues?: FormikValues;
  signUp?: boolean;
}
export default function SSOForm({ onBackClick, initialValues, signUp = false }: Props) {
  const buttonText = signUp ? "Create account" : "Log in";
  const isSubbmittingText = signUp ? "Creating account..." : "Logging in...";
  return (
    <Formik
      initialValues={initialValues || { email_address: "" }}
      validationSchema={Yup.object({
        email_address: Yup.string().email("Invalid email").required("Required"),
      })}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus();
        try {
          await handleSSOLogin("SAML", values.email_address);
        } catch (error: any) {
          console.log(error);
          if ([400, 401, 403].includes(error.response.status)) {
            console.log("Invalid email address");
            setStatus({ error: "Domain not registered for SAML/SSO" });
          }
        }
      }}
    >
      {({ isSubmitting, status, setStatus }) => (
        <Form className="flex flex-col gap-24">
          <FormikTextInput
            type="email"
            name="email_address"
            label="Work email"
            placeholder={"you@company.com"}
            tabIndex={1}
            privacyLevel="moderate"
          />
          <FormStatus status={status} resetStatus={() => setStatus()} />
          <Button type="submit" size={40} styling="solid" shade="black" className="w-full" loading={isSubmitting}>
            {isSubmitting ? isSubbmittingText : buttonText}
          </Button>
          {!isSubmitting && (
            <div className="absolute left-24 top-24">
              <IconButton onClick={onBackClick} Icon={ArrowLeftIcon} className="text-oldgray-600" size={32} />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
