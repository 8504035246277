import { handleSSOLogin, AuthProvider } from "@/services/auth.service";
import Button from "@components/library/Button";
import Image from "next/image";

export type AuthButtonRole = "Sign up" | "Log in";

interface AuthSelectorProps {
  onEmail: () => void;
  onSSO: () => void;
  role: AuthButtonRole;
}

interface OAuthProvider {
  name: string;
  provider: AuthProvider;
  imgSrc: string;
  imgAlt: string;
  imgWidth: number;
  imgHeight: number;
  imgClass: string;
}

// OAuth provider configuration
const oauthProviders: OAuthProvider[] = [
  {
    name: "Google",
    provider: "GoogleOAuth",
    imgSrc: "/assets/companies/google-mark.svg",
    imgAlt: "Google Logo",
    imgWidth: 22,
    imgHeight: 22,
    imgClass: "-my-4 -ml-[17px]",
  },
  // TODO: Can turn on later
  // {
  //   name: "GitHub",
  //   provider: "GitHubOAuth",
  //   imgSrc: "/assets/companies/github-mark.svg",
  //   imgAlt: "GitHub Logo",
  //   imgWidth: 15,
  //   imgHeight: 15,
  //   imgClass: "-my-4 -ml-[12px] mr-4",
  // },
  // {
  //   name: "Microsoft",
  //   provider: "MicrosoftOAuth",
  //   imgSrc: "/assets/companies/ms-mark.svg",
  //   imgAlt: "Microsoft Logo",
  //   imgWidth: 15,
  //   imgHeight: 15,
  //   imgClass: "-ml-[-4px] mr-2",
  // },
];

export const AuthSelector = ({ onEmail, onSSO, role }: AuthSelectorProps) => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-10">
      {oauthProviders.map((provider) => (
        <Button
          key={provider.name}
          size={40}
          className="w-full"
          onClick={() => handleSSOLogin(provider.provider)}
          elevated
        >
          <Image
            src={provider.imgSrc}
            alt={provider.imgAlt}
            width={provider.imgWidth}
            height={provider.imgHeight}
            className={provider.imgClass}
          />
          {role} with {provider.name}
        </Button>
      ))}
      <Button className="w-full" onClick={onEmail} size={40}>
        {role} with email
      </Button>
      <Button className="w-full" onClick={onSSO} size={40}>
        {role} with SAML / SSO
      </Button>
    </div>
  );
};
