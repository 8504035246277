import ProfileDropdown from "../ProfileDropdown";
import { LissajousLogo } from "@components/library/LissajousLogo";
import { DOCS_LINK } from "lib/constants";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export const HelpSnippet = () => (
  <div className="flex gap-8 text-12-16 text-oldgray-800">
    <div>Need help?</div>
    <div className="flex gap-[3px]">
      <Link href={DOCS_LINK} target="_blank" className="text-oldblue-600">
        Visit docs
      </Link>{" "}
      or{" "}
      <a href="mailto:help@humanloop.com" className="text-oldblue-600">
        contact us
      </a>
    </div>
  </div>
);

export const SignupLoading = ({ text }: { text?: string }) => (
  <div className="flex w-[320px] animate-pulse flex-col items-center gap-10">
    <LissajousLogo className="h-[72px] w-[72px] opacity-10" />
    <div className="flex items-center justify-center text-20-24 font-bold text-oldgray-400">{text}</div>
  </div>
);

export const SignupLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="relative flex min-h-screen">
      <div className="absolute right-20 top-20 z-10">
        <ProfileDropdown hideName hideLinks />
      </div>
      <main className="flex w-full flex-col items-center justify-center px-12 py-40 md:w-1/2">
        <div className="flex w-[320px] flex-col items-center justify-center">{children}</div>
      </main>
      <aside className="hidden bg-gray-125 md:fixed md:inset-y-0 md:right-0 md:block md:w-1/2">
        <Image
          className="h-full w-full object-cover"
          src="/assets/backgrounds/signup.jpg"
          height={1080}
          width={1920}
          alt="Photo by Xavier Teo"
        />
      </aside>
    </div>
  );
};

export default SignupLayout;
