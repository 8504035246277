/*
Checks if URL is a relative url.
Allows safe use of router.replace/push, preventing reflected XSS attacks.
(e.g. where these attributes are set to `javascript:alert(1)`)
*/
export const isRelativeURL = (str: string): boolean => {
  // Regex to match relative URLs '/blah', but not protocol-relative URLs '//badsite.com'
  // https://regexr.com/7i7jb
  const relativeURLRegex = /^\/(?!\/)/;
  return relativeURLRegex.test(str);
};

export const safeQueryParamRedirect = (queryParam?: string | string[]): string => {
  // Query params can be arrays if the same key is used multiple times.
  const fallback = "/";
  if (!queryParam) return fallback;
  if (typeof queryParam === "string") {
    return isRelativeURL(queryParam) ? queryParam : fallback;
  } else if (queryParam && Array.isArray(queryParam)) {
    return isRelativeURL(queryParam[0]) ? queryParam[0] : fallback;
  }
  return fallback;
};
