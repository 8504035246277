import IconButton from "@components/library/IconButton";
import { hlToast, ToastVariant } from "@components/library/Toast";
import { XIcon } from "@heroicons/react/solid";
import Button from "@library/Button";
import { Form, Formik } from "formik";
import { safeQueryParamRedirect } from "lib/xss-prevention";
import Link from "next/link";
import { useRouter } from "next/router";
import * as Yup from "yup";
import { useAuth } from "../../../lib/use-auth";
import { FormikTextInput } from "../../molecules/FormikInput";
import React from "react";
import { FORGOT_PASSWORD_URL } from "@/lib/constants";
import { ArrowLeftIcon } from "@heroicons/react/solid";

interface Props {
  onBackClick: () => void;
}
export default function LoginForm({ onBackClick }: Props) {
  const router = useRouter();
  const auth = useAuth();

  const nextUrl = safeQueryParamRedirect(router.query.next);
  const forgotPasswordUrl =
    FORGOT_PASSWORD_URL + (router.asPath.split("?")[1] ? `?${router.asPath.split("?")[1]}` : "");

  return (
    <Formik
      initialValues={{ email_address: "", password: "" }}
      validationSchema={Yup.object({
        email_address: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().min(8, "Passwords are longer than 8 characters").required("Required"),
      })}
      onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
        setSubmitting(true);
        setStatus();
        try {
          const response = await auth.login(values.email_address, values.password);
          if (response.status === 200) {
            router.push(nextUrl);
          }
        } catch (e: any) {
          if ([401, 403].includes(e.response.status)) {
            setStatus({ error: "Incorrect email or password" });
            return;
          } else if (e.response.status === 422 && e.response.data?.detail?.type === "value_error.user_verification") {
            setStatus({ error: "Email is not yet verified" });
            return;
          } else if (e.response.status === 429) {
            setStatus({ error: "Too many login attempts. Please try again later." });
            return;
          }
          hlToast({
            title: "Something went wrong",
            description: "Please try again later",
            variant: ToastVariant.Error,
          });
        }
      }}
    >
      {({ errors, isSubmitting, status, setStatus }) => (
        <Form className="flex flex-col gap-32">
          <div className="flex flex-col gap-24">
            <FormikTextInput type="email" name="email_address" label="Email" tabIndex={1} privacyLevel="moderate" />
            <FormikTextInput
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              tabIndex={2}
              privacyLevel="high"
              corner={
                <Link
                  href={forgotPasswordUrl}
                  className="transition-default font-medium text-oldblue-600 hover:text-oldblue-700 focus:underline focus:outline-none"
                >
                  Forgot password?
                </Link>
              }
            />
          </div>
          <FormStatus status={status} resetStatus={() => setStatus()} />
          <Button type="submit" size={40} styling="solid" className="w-full" shade="black" loading={isSubmitting}>
            {isSubmitting ? "Logging in..." : "Log in"}
          </Button>

          {!isSubmitting && (
            <div className="absolute left-24 top-24">
              <IconButton onClick={onBackClick} Icon={ArrowLeftIcon} className="text-oldgray-600" size={32} />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}

interface FormStatusProps {
  status: {
    error: string;
  };
  resetStatus: () => void;
}

export const FormStatus: React.FC<FormStatusProps> = ({ status, resetStatus }) => {
  if (!status?.error) return null;
  return (
    <div className="mt-16 flex animate-fade-in-down items-center justify-between rounded border border-oldred-300 bg-oldred-100 px-16 py-12">
      <div className="text-14-16 text-oldred-800">{status.error}</div>
      <IconButton onClick={resetStatus} Icon={XIcon} className="text-oldred-500" size={32} />
    </div>
  );
};
