import React, { useState, useEffect } from "react";
import { FEATURES } from "lib/constants";
import * as Bowser from "bowser";
import { XIcon } from "@heroicons/react/solid";

export default function DeviceSupport() {
  const [engine, setEngine] = useState<string | null>(null);

  const [isAcknowledged, setIsAcknowledged] = useState<boolean | null>(null);

  const [operatingSystem, setOperatingSystem] = useState<string | null>(null);

  useEffect(() => {
    const userAgent = Bowser.parse(window.navigator.userAgent);

    const previouslyAcknowledged = !!window.localStorage.getItem("DeviceSupportAcknowledged");

    if (previouslyAcknowledged) {
      console.log(`User previously accepted the browser support message`);
    }

    setIsAcknowledged(previouslyAcknowledged);

    setEngine(userAgent?.engine?.name || null);
    setOperatingSystem(userAgent?.os?.name || null);

    if (operatingSystem === "Windows") {
      window.document.body.classList.add("windows");
    }
  }, [engine, operatingSystem]);

  const isBlink = engine === "Blink";

  function hideOldBrowserWarning() {
    console.log(`Hiding browser notice`);
    window.localStorage.setItem("DeviceSupportAcknowledged", "true");
    setIsAcknowledged(true);
  }

  const showNotice = !isBlink && !isAcknowledged;

  if (!FEATURES.WARN_OTHER_BROWSERS) {
    return null;
  }

  if (!showNotice) {
    return null;
  }

  return (
    <section
      className="hl-non-chromium-notice text-md text-bold sticky top-0 grid w-full grid-flow-col content-center justify-center bg-oldblue-600 p-1 text-center text-white"
      style={{
        gridTemplateColumns: "auto 14px",
      }}
    >
      <p>For the best experience, please use Google Chrome or Microsoft Edge</p>
      <XIcon className={`fixed right-8 top-8 w-20 cursor-pointer`} onClick={() => hideOldBrowserWarning()} />
    </section>
  );
}
